.footer__bar {
    display: flex;
    background-color: rgba(241, 241, 241, 0.63);
    justify-content: center;
    padding: 1rem;
    font-size: 14pt;
}

/* Media Queries (Medium Devices) */
@media (max-width: 1024px) {
}

/* Media Queries (Small Devices) */
@media (max-width: 600px) {
    .footer__bar {
        font-size: 11pt;
        padding: 0.8rem;
    }
}
