.navigation__bar {
    display: flex;
    gap: 9vw; /*15rem*/
    font-size: 20pt;
    height: 5rem;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    position: sticky;
    top: -1px;
    scroll-behavior: smooth;
    transition: 200ms ease;
}

.navigation__sticky {
    background-color: white;
    z-index: 999;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 10px 20px rgba(0, 0, 0, .15);
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 10px 20px rgba(0, 0, 0, .15);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 10px 20px rgba(0, 0, 0, .15);
}

.navigation__settings {
    display: flex;
    gap: 30%;
    width: 50%;
    justify-content: center;
}

.navigation__settings > div {
    color: #414042;
    font-size: 14pt;
}

.navigation__settings > div:hover {
    cursor: pointer;
}

.navigation__socials-section {
    width: 25%;
}

.navigation__socials, .navigation__sidebar-socials {
    display: flex;
    /*gap: 2rem;*/
}

.navigation__socials > a, .navigation__sidebar-socials > a {
    display: flex;
    color: #414042 !important;
    align-items: center;
}

.navigation__social-spacing > a {
    justify-content: center;
    width: 50px;
}

.navigation__socials > a:hover {
    color: #e1e1e1 !important;
}

.navigation__small-bar {
    display: none;
}

.navigation__sidebar-close {
    align-self: flex-end;
    font-size: 22pt;
    padding-right: 1.2rem;
    padding-bottom: 1.2rem;
}

.navigation__sidebar {
    background-color: white;
    width: 160px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    z-index: 999;
}

.navigation__sidebar.active {
    right: 0;
    transition: 350ms;
}

.navigation__sidebar-settings {
    display: flex;
    flex-direction: column;
    margin-top: 20%;
    gap: 1.5rem;
}

.navigation__sidebar-settings__item {
    font-size: 16pt;
}

.navigation__sidebar-socials {
    font-size: 16pt;
    gap: 1rem;
}

/* Media Queries (Medium Devices) */
@media (max-width: 1024px) {

    .navigation__bar {
        gap: 4rem; /*1rem*/
        background-color: white;
        z-index: 998;
        -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 10px 20px rgba(0, 0, 0, .15);
        -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 10px 20px rgba(0, 0, 0, .15);
        box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 10px 20px rgba(0, 0, 0, .15);
    }

    .navigation__settings {
        font-size: 16pt;
        gap: 12vw; /*1.2rem*/
    }

    .navigation__socials {
        gap: 1rem;
    }

}

/* Media Queries (Custom) */
@media (max-width: 900px) {
    .navigation__bar {
        justify-content: space-between;
    }

    .navigation__settings, .navigation__socials {
        display: none;
    }

    .navigation__small-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3%;
        padding-right: 5% !important;
    }
}