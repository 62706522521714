.error__container {
    min-height: calc(100vh - 13rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
}

.error__button {
    width: auto !important;
}


/* Media Queries (Medium Devices) */
@media (max-width: 1024px) {

}

/* Media Queries (Small Devices) */
@media (max-width: 600px) {

}