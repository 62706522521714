.search__button {
    color: #414042 !important;
    display: inline-block;
    height: 50px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}

.search__button:hover {
    transition: 400ms ease;
}

.search-container {
    position: relative;
    /*display: inline-block;*/
    height: 50px;
    width: 50px;
    /*vertical-align: bottom;*/
}

.searchbutton {
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 0;
}

.search:focus + .searchbutton {
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    background-color: white;
    color: black;
}

.search {
    position: absolute;
    left: 49px; /* Button width-1px (Not 50px/100% because that will sometimes show a 1px line between the search box and button) */
    background-color: white;
    outline: none;
    border: none;
    padding: 0;
    width: 0;
    height: 100%;
    z-index: 10;
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
}

.search:focus {
    width: 363px; /* Bar width+1px */
    padding: 0 16px 0 0;
}

.expandright {
    left: auto;
    right: 49px; /* Button width-1px */
}

.expandright:focus {
    padding: 0 0 0 16px;
}

/* Media Queries (Custom Devices) */
@media (max-width: 900px) {
    .search:focus {
        width: 126px; /* Bar width+1px */
        padding: 0 16px 0 0;
    }
}