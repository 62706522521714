.dashboard__container {
    text-align: center;
    margin-top: 5%;
    margin-bottom: 10%;
}

.dashboard__options {
    display: flex;
    flex-direction: row;
    margin: auto 10%;
    gap: 1rem;
    justify-content: center;
}

.dashboard__buttons-row {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
}

.dashboard-btn__delete {
    font-size: 12pt;
    padding: 0.3rem 0.6rem;
    background: none;
    border: none;
    text-decoration: underline;
}

.dashboard-btn__delete:hover {
    font-style: italic;
}

.dashboard__remove-product {
    text-align: left;
    font-size: 14pt;
}

.dashboard__product-list, .dashboard__edit-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 3% 5%;
    gap: 2rem;
}

.dashboard__edit-item {
    width: 20%;
    font-size: 16pt;
    border: 1pt solid black;
    border-radius: 0.8rem;
    padding: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard__subtext {
    margin-top: 1rem;
}

.dashboard__error {
    font-size: 18pt;
    margin-top: 10%;
    margin-left: 10%;
    margin-right: 10%;
}

/* Media Queries (Medium Devices) */
@media (max-width: 1024px) {

}

/* Media Queries (Small Devices) */
@media (max-width: 600px) {

    .dashboard__container {
        margin-bottom: 5rem;
    }

    .dashboard__product-list {
        gap: 1rem;
    }

    .dashboard__edit-item {
        width: 40%;
    }
}