.products__container {
    min-height: calc(100vh - 10rem);
    margin-bottom: 1rem;
}

.products__search {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.products__search > input {
    margin-top: 1%;
    margin-bottom: 1%;
    width: 40%;
    height: 2.2rem;
    padding: 0.5rem 1.2rem !important;

    font-size: 1.3rem;
    letter-spacing: 1px;
    font-weight: 400;
    line-height: 1.5;
    color: black;

    border-radius: 30px;
    border: 1px solid rgba(71, 71, 71, 0.52);
}

.products__categories {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    overflow: scroll;
    scroll-behavior: smooth;
    justify-content: space-between;
    margin-left: 3%;
    margin-right: 3%;
    padding-left: 2%;
    padding-right: 2%;
}

.products__categories-selected, .products__category-option {
    border: none;
    padding: 0.6% 1.8%;
    text-align: center;
    font-size: 16pt;
    transition: 300ms ease;
    border-radius: 2rem;
}

.products__category-option:hover {
    background-color: var(--yellow-light);
}

.products__categories-selected {
    background-color: var(--yellow-light);
    border: none !important;
}

.products__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4rem;
    margin: 3% 0;
    justify-content: center;
}

.products__card-item {
    box-shadow: rgb(255 255 255) -5px -5px 13px, rgb(214 214 214) 5px 5px 13px;
    padding: 0 0 2rem;
    text-align: center;
    width: 25% !important;
    display: flex;
    flex-direction: column;
    border-radius: 1.5rem !important;
    transition: 400ms ease;
}

.products__card-item:hover {
    box-shadow: 0 0 2rem 0 #8f8f8f8c;
    width: 27% !important;
    padding-bottom: 3rem;
}

.products__card-item > a {
    text-decoration: none;
    color: black;
}

.products__card-image {
    width: 100% !important;
    max-width: 100% !important;
    position: relative;
    margin: 0 0 2%;
    padding: 0;
    border: 0;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    object-fit: cover;
    height: 280px;
}

.products__card-image-unavailable {
    font-size: large;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 12rem;
    height: 300px;
    color: rgba(68, 68, 68, 0.9);
}

.products__card-name {
    font-size: 16pt;
    padding-bottom: 0.4rem;
}

.products__card-price {
    font-size: 14pt;
    font-weight: bold;
}

.menu__no-products {
    font-size: 27pt;
    text-align: center;
}

/* Media Queries (Medium Devices) */
@media (max-width: 1024px) {

    .products__categories {
        padding-bottom: 3%;
    }

    .products__cards {
        gap: 2rem;
    }

    .products__card-item {
        width: 44% !important;
    }

    .products__search > input {
        width: 70%;
    }

}

/* Media Queries (Small Devices) */
@media (max-width: 600px) {

    .products__search > input {
        width: 60%;
        height: 1.8rem;
        font-size: 1.1rem;
        padding: 0.3rem 1.1rem !important;
    }

    .products__categories {
        padding-bottom: 5%;
        justify-content: flex-start;
        gap: 1rem;
    }

    .products__cards {
        gap: 1rem;
        margin: 3% 0.5%;
    }

    .products__card-item {
        width: 80% !important;
        border: none;
        box-shadow: none;
        padding: 1rem 0;
    }

    .products__card-item:hover {
        box-shadow: none;
        width: 80% !important;
        padding: 1rem 0;
    }

    .products__card-name {
        font-size: 15pt;
    }

    .products__card-price {
        font-size: 13pt;
    }

    .products__card-image {
        border-radius: 0.5rem;
    }

    .products__card-image-unavailable {
        font-size: 12pt;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .products__categories-selected, .products__category-option {
        padding: 0.2rem 0.7rem;
    }

}