.upload__container {
    border: 1pt solid #646464;
    padding: 2rem;
    border-radius: 0.5rem;
}

/* Media Queries (Medium Devices) */
@media (max-width: 1024px) {

}

/* Media Queries (Small Devices) */
@media (max-width: 600px) {

}