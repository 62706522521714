.admin-navigation__bar {
    display: flex;
    font-size: 20pt;
    height: 6rem;
    width: 100vw;
    align-items: center;
    justify-content: space-around;
    position: sticky;
    top: -1px;
    scroll-behavior: smooth;
    transition: 200ms ease;
    gap: 50%;
}

.admin-navigation__sticky {
    background-color: white;
    z-index: 999;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 10px 20px rgba(0, 0, 0, .15);
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 10px 20px rgba(0, 0, 0, .15);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 10px 20px rgba(0, 0, 0, .15);
}

/* Media Queries (Medium Devices) */
@media (max-width: 1024px) {
    .admin-navigation__bar {
        gap: 1rem;
        background-color: white;
        z-index: 998;
        -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 10px 20px rgba(0, 0, 0, .15);
        -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 10px 20px rgba(0, 0, 0, .15);
        box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 10px 20px rgba(0, 0, 0, .15);
    }

}

/* Media Queries (Small Devices) */
@media (max-width: 600px) {
    .admin-navigation__bar {
        padding-left: 0;
        padding-right: 0;
    }

    .sign-out__button {
        font-size: 14pt;
    }
}