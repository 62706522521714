.trademark-btn {
    background: linear-gradient(0.25turn, var(--yellow-light), #f7a91c);
    border: none;
    border-radius: 1.6rem;
    margin-top: 2rem;
    padding: 1rem 1.5rem;
    width: 10rem;
    font-size: 14pt;
    font-family: "Inter", sans-serif;
    color: #414042;
    box-shadow: 0 0 15px 8px rgb(245 204 69 / 46%);
    transition: 200ms ease;
}

.trademark-btn:hover {
    box-shadow: 0 0 11px 12px rgb(245 204 69 / 64%)
}

.form-btn {
    font-size: 14pt;
    border-radius: 2rem;
    padding: 0.5rem 0.8rem;
    border: 0.8pt solid rgba(136, 136, 136, 0.26);
    transition: 400ms ease;
    background-color: #e1e2e7;
}

.form-btn__secondary {
    font-size: 12pt !important;
}

.form-btn__tertiary {
    border-radius: 1rem;
    padding: 0.3rem 0.6rem;
    border: 0.8pt solid rgba(136, 136, 136, 0.26);
    transition: 400ms ease;
    background-color: #e1e2e7;
}

.form-btn__add {
    background-color: rgba(128, 234, 128, 0.6);
}

.form-btn__remove {
    background-color: rgba(248, 114, 114, 0.42);
}

.form-btn__yellow {
    background-color: rgba(255, 244, 123, 0.66);
}

.form-btn__back {
    height: 3rem;
    width: 7rem;
    font-size: 14pt;
    border-radius: 2rem;
    border: 0.8pt solid rgba(136, 136, 136, 0.26);
    transition: 400ms ease;
    background-color: #e1e2e7;
}

.form-btn__back:hover {
    font-style: italic;
    background-color: transparent;
}

.form-btn:hover, .form-btn__tertiary:hover {
    background-color: transparent;
}

.form-btn__tertiary:hover {
    font-style: italic;
}

.form-btn:active, .form-btn__tertiary:active {
    background-color: transparent;
}
