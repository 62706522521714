:root {
    --swiper-theme-color: #b99500 !important;
}

.product__section {
    min-height: calc(100vh - 9rem);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.product__images {
    padding-top: 5%;
    width: 50%;
    text-align: center;
    flex: 4;
}

.product__image-selected {
    width: 85%;
    margin-bottom: 7%;
    margin-top: 5%;
}

.product__images-selectors {
    display: flex;
    justify-content: center;
    gap: 2%;
    max-width: 100% !important;
    scroll-behavior: smooth;
    overflow: scroll;
    margin-top: 4%;
}

.product__image-option {
    width: 13rem;
}

.product__information {
    width: 50%;
    flex: 3;
}

.product__info-area {
    margin: 18% 12% 10% 5%;
}

.product__info-name {
    text-align: center;
    font-size: 38pt;
    margin-bottom: 1%;
}

.product__info-price {
    text-align: center;
    font-size: 22pt;
}

.product__info-description > h4 {
    font-size: 24pt;
    margin-top: 7%;
    margin-bottom: 2%;
}

.product__info-description > p {
    text-indent: 2rem;
    font-size: x-large;
    margin-top: 2%;
}

.product__info-allergens {
    display: flex;
    flex-direction: row;
    font-size: larger;
}

.product__info-allergens > h5 {
    font-size: 20pt;
    margin-top: 4%;
    margin-bottom: 3%;
}

.product__swiper {
    margin: auto 2%;
}

/* Media Queries (Medium Devices) */
@media (max-width: 1024px) {
    .product__section {
        flex-direction: column;
        justify-content: normal;
    }

    .product__images, .product__information {
        width: auto;
    }

    .product__images {
        flex: 1;
    }

    .product__image-selected {
        margin: 5% 5% 3%;
    }

    .product__images-selectors {
        margin-top: auto;
    }

    .product__info-area {
        margin: 0 3% 3%;
    }

    .product__info-name {
        font-size: 32pt;
        margin-top: 0;
    }

    .product__details {
        margin: 0 7%;
    }

    .product__info-description > p {
        text-indent: 0;
    }

    .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: -4px !important;
    }

}

/* Media Queries (Small Devices) */
@media (max-width: 600px) {

    .product__section {
        min-height: calc(100vh - 7.5rem);
    }

    .product__info-area {
        margin: 3%;
    }

    .product__info-name {
        font-size: 24pt;
        margin-bottom: 5%;
    }

    .product__info-price, .product__info-description > h4 {
        font-size: 15pt;
    }

    .product__info-description > p {
        text-indent: 0;
        font-size: 17pt;
    }

    .product__image-selected {
        width: 90%;
        margin-bottom: 6%;
    }

    .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: -4px !important;
    }

    .swiper-button-next, .swiper-button-prev {
        display: none;
    }

}