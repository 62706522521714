:root {
    --yellow-trademark: #f5cc45;
    --yellow-light: #fed107;
}

h1, h2, h3, h4, h5, h6, span, div, a, input, textarea {
    font-family: "Inter", sans-serif;
    /*font-family: 'Crimson Text', serif;*/

}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'KastingScript';
    src: local('KastingScript'), url(./assets/fonts/KastingScript.ttf) format('truetype');
}

.sidebar__view-false {
    display: block;
}

.sidebar__view {
    display: flex;
}

.page__management {
    margin-top: 6rem;
}

.page__height {
    min-height: calc(100vh - 9em);
}

/* Media Queries (Medium Devices) */
@media (max-width: 1024px) {
}

/* Media Queries (Small Devices) */
@media (max-width: 600px) {

}