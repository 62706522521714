/*Greetings Section*/
.home__container {
    position: relative;
    min-height: calc(100vh - 8.5rem);
    /*height: calc(100vh - 8.5rem);*/
}

.home__greetings {
    min-height: calc(100vh - 8.5rem);
}

.home__left {
    display: flex;
    flex-direction: column;
    padding-left: 5rem;
}

.home__left > h1, h2 {
    font-family: "Inter", sans-serif;
}

.home__left > h1 {
    color: #414042;
    font-size: 36pt;
    padding-top: 3%;
    margin-bottom: 0;
}

.home__left > h2 {
    color: #58595b;
}

.home__icons, .home__icons > div {
    display: flex;
    font-family: "Inter", sans-serif;
    color: #58595b !important;
}

.home__icons > div {
    gap: 1rem;
}

.home__icons {
    gap: 3rem;
    position: absolute;
    bottom: 5%;
    left: 40%;
}

/* Media Queries (Medium Devices) */
@media (max-width: 1024px) {
    .home__greetings {
        min-height: auto;
    }

    .home__menu-btn {
        align-self: center;
    }

    .home__icons {
        flex-direction: column;
        left: auto;
        right: 5%;
        padding-right: 1rem;
        gap: 1.5rem;
    }

    .home__about-section {
        flex-direction: column;
    }

    .home__left {
        padding-left: 2rem;
        padding-right: 2rem;
    }

}

/* Media Queries (Small Devices) */
@media (max-width: 600px) {
    .home__greetings {
        padding-top: 0;
    }

    .home__left > h1 {
        font-size: 28pt;
    }

    .home__menu-btn {
        margin-top: 1rem !important;
        margin-bottom: 2rem;
    }

    .home__icons {
        right: 2rem;
        left: auto;
        top: auto;
        visibility: hidden;
    }
}