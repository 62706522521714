.sign-in__page {
    height: 100vh !important;
    display: flex;
    background-color: rgba(190, 190, 190, 0.55);
    justify-content: center;
}

.sign-in__box {
    margin-top: 10%;
    border: 1pt solid rgba(0, 0, 0, 0.53);
    background-color: white;
    height: fit-content;
    width: 40%;
}

.sign-in__box > h2 {
    text-align: center;
}

.sign-in__form {
    display: flex;
    flex-direction: column;
    padding-bottom: 5%;
}

.sign-in__form > input {
    margin: 2% 5%;
    font-size: 16pt;
}

.sign-in__form > button {
    width: fit-content;
    padding: 1% 3%;
    align-self: center;
    margin-top: 3%;
    font-size: 16pt;
}

.sign-in__remember {
    display: flex;
    justify-content: center;
}

.sign-in__response {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    text-align: center;
    color: #a90808;
    font-weight: bold;
}

/* Media Queries (Medium Devices) */
@media (max-width: 1024px) {

}

/* Media Queries (Small Devices) */
@media (max-width: 600px) {
    .sign-in__box {
        width: 80%;
        margin-top: 25%;
    }
}