.logo__image {
    height: 70% !important;
}

.spoon__image {
    position: absolute;
    bottom: 0;
    padding-left: 0 !important;
    width: 38%;
}

.blobs__image {
    position: absolute;
    top: 0;
    right: 0;
    width: 58%;
    z-index: -1;
}

.location__image {
    width: 90%;
    border-radius: 0.5rem;
}

/*Ramadan Images*/
.clouds__image {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30%;
}

.mosque__image {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 80%;
}

.lantern__image {
    position: absolute;
    top: 0;
    height: 40%;
}

.lantern__left {
    left: 18%;
}

.lantern__right {
    right: 18%;
}

.stars__image {
    position: absolute;
    height: 40%;
    top: 0;
    max-width: 85%;
    z-index: 1;
}

.moon__image {
    position: absolute;
    top: 27%;
    left: 3%;
    height: 20%;
}

.moon__image-eid {
    position: absolute;
    top: 5%;
    height: 20%;
    left: 5%;
}

.eid-tart__image {
    max-height: 420px;
    border-radius: 4rem;
    box-shadow: 0 0 15px 8px rgb(222 179 38 / 89%);
}


/* Media Queries (Medium Devices) */
@media (max-width: 1024px) {
    .blobs__image {
        display: none;
    }

    .spoon__image {
        width: 60%;
        margin-top: 2rem;
    }

    /*Ramadan Images*/
    .stars__image {
        height: 40%;
    }

    .clouds__image {
        width: 50%;
    }
}

/* Media Queries (Custom Devices) */
@media (max-width: 900px) {
    .logo__image {
        height: 80% !important;
        padding-left: 5% !important;
    }

    .mosque__image {
        height: 60%;
    }

    .moon__image {
        top: 40%;
    }

    .eid-tart__image {
        max-height: 300px;
    }
}

/* Media Queries (Small Devices) */
@media (max-width: 600px) {
    .spoon__image {
        display: inline;
        width: 80%;
    }

    .stars__image {
        width: 80%;
        height: auto;
    }

    .lantern__image {
        height: 35%;
    }

    .moon__image {
        top: auto;
        bottom: 17%;
        height: 16%;
    }

    .mosque__image {
        height: 40%;
    }

    .clouds__image {
        visibility: hidden;
    }

    .moon__image-eid {
        visibility: hidden;
    }
}

/*Media Queries (Custom Height)*/
@media (max-height: 700px) {

    .spoon__image {
        display: none;
    }
}

