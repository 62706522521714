.fp__section {
    padding: 1rem 0 2rem;
}

.fp__container {
    background-color: #f2f2f2;
}

.fp__section-header {
    margin: 0 5%;
}

.fp__section-header > h2 {
    font-weight: normal;
}

.fp__swiper {
    padding-left: 5% !important;
    padding-right: 5% !important;
}

.fp__link {
    text-decoration: none;
    color: black;
}

.fp__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
}

.fp__image {
    width: 100%;
    object-fit: cover;
    height: 300px;
}

.fp__details {
    position: relative;
    bottom: -5px;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.fp__button {
    border: 0.8pt solid #5e5e5e;
    text-align: center;
    transition: 300ms ease;
    border-radius: 2rem;
    padding: 0.5rem 1.3rem;
    width: 10rem;
    max-width: 100%;
    margin-bottom: 2rem;
}

.fp__button:hover {
    background-color: var(--yellow-light);
    border: 0.8pt solid var(--yellow-light);
}

.fp__swiper-options {
    min-height: fit-content;
    padding-bottom: 1rem;
}

.swiper-wrapper {
    align-items: flex-end;
}

.fp__swiper > .swiper-button-next, .fp__swiper > .swiper-button-prev {
    top: 45%;
}

.fp__swiper > .swiper-button-prev {
    left: 3rem;
}

.fp__swiper > .swiper-button-next {
    right: 3rem;
}

/* Media Queries (Medium Devices) */
@media (max-width: 1024px) {
    .fp__section {
        padding-bottom: 0;
    }

    .fp__container {
        padding: 0 0 2rem;
    }

    .fp__swiper > .swiper-button-prev {
        left: 0.4rem;
    }

    .fp__swiper > .swiper-button-next {
        right: 0.4rem;
    }
}

/* Media Queries (Small Devices) */
@media (max-width: 600px) {

}