.loading__container {
    height: 85vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.loading {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 14px solid;
    border-color: #f5cc45 rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
    animation: spin .6s ease infinite;
}

.loading__text {
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 4px;
    font: 300 32px/1 "Quicksand", sans-serif;
    color: black;
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(359deg)
    }
}

.css-i4bv87-MuiSvgIcon-root {
    fill: black !important;

}

.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
    fill: black !important;
}