.form__page {
    padding-bottom: 5rem;
}

.form__header {
    display: flex;
    flex-direction: row;
    margin: 1% 15%;
    justify-content: space-between;
    align-items: center;
}

.form__container {
    display: flex;
    flex-direction: column;
    margin: 1% 15%;
    gap: 1rem;
    border: 1pt #5e5e5e solid;
    border-radius: 2rem;
    padding: 2rem 4rem;
}

.form__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.form__component {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 3px;
    justify-content: center;
}

.form__component > label {
    text-align: left;
    font-size: 14pt;
}

.form__component > input, textarea {
    font-size: 14pt;
    padding: 0.2rem 0.8rem;
}

.css-b62m3t-container {
    flex: 1 !important;
}

.form-price__add {
    margin-top: 3%;
}

.form-price__remove {
    text-decoration: underline;
    text-align: center !important;
}

.form-price__remove:hover {
    font-style: italic;
}

.form__add-btn {
    width: fit-content;
    padding: 0.5rem 1.1rem;
    align-self: center;
}

.form__image-upload {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.form__images-grid {
    display: flex;
    flex-direction: row;
    gap: 3%;
    flex-wrap: wrap;
    justify-content: center;
}

.form__image {
    width: 30%;
}

.form__image > img {
    width: 100%;
}

.form__upload-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2rem;
    margin: 2% 3% 2% 20%;
}

.form__image-options {
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    justify-content: center;
}

.form__header-small {
    display: none;
}

/*TODO color green on success*/
.form__response {
    color: darkred;
    font-weight: bold;
}

/* Media Queries (Medium Devices) */
@media (max-width: 1024px) {

}

/* Media Queries (Small Devices) */
@media (max-width: 600px) {

    .form__container {
        border: none;
        padding: 0;
        margin: 1% 10%;
    }

    .form__row {
        flex-direction: column;
        width: 100%;
        margin-top: 0;
    }

    .form__component {
        width: 95%;
    }

    .form__image {
        width: 40%;
    }

    .form__header {
        display: none;
    }

    .form__header-small {
        display: flex;
        flex-direction: column;
        margin: 1% 5%;
        justify-content: space-between;
        align-items: center;
    }

    .form__header-small > div {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        margin-bottom: 2rem;
    }
}