.visit__section {
    padding: 1rem 0 3rem;
    /*background-color: rgba(248, 210, 39, 0.4);*/
}

.visit__section > h2 {
    font-size: 19pt;
    font-weight: normal;
    text-align: center;
    width: 100%;
}

.visit__row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
}

.visit__location {
    min-height: fit-content;
    padding: 1rem 0;
    width: 35%;
    border-radius: 2rem;
    position: relative;
    box-shadow: 0 11px 12px 8px rgba(161, 161, 161, 0.78);
    border: 0.5pt solid #b7b7b7;
    display: flex;
    align-items: center;
    justify-content: center;
}

.visit__information {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0 1rem;
    border-radius: inherit;
    text-align: center;
    font-size: 15pt;
    width: 100%;
}

.visit__map {
    border: 0;
    width: 95%;
    aspect-ratio: 1.7;
}

.visit__link {
    background-color: var(--yellow-light);
    overflow: hidden;
    padding: 1rem 1.3rem;
    border-radius: inherit;
}

.visit__link > a {
    color: black;
    text-decoration: none;
}

.visit__link > a:hover {
    font-style: italic;
}

@media (max-width: 700px) {
    .visit__row {
        flex-direction: column;
        align-items: center;
        gap: 3rem;
    }

    .visit__location {
        width: 80%;
    }
}