.contact-us__section {
    margin-bottom: 4rem;
    min-height: calc(100vh - 15rem);
}

.home-contact-us__section {
    margin-bottom: 4rem;
}

.contact-us__heading {
    font-weight: normal;
    font-size: 28pt;
    text-align: center;
}

.contact-us__information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-us__information > p {
    font-size: 15pt;
    margin-bottom: 2rem;
    text-align: center;
}

.contact-us__information > span {
    font-size: 15pt;
}

.contact-us__information > a {
    font-size: 16pt;
    padding: 0.2rem;
    color: rgb(43, 43, 210);
}

.contact-us__form {
    margin: 3% 10%;
    display: flex;
    flex-direction: column;
}

.contact-us__form__message > textarea {
    height: 7rem;
}

.contact-us__form__name, .contact-us__form__email, .contact-us__form__message {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    font-size: 18pt;
}

.contact-us__labels {
    margin: 0 0 0.7% 1.5%;
}

.contact-us__form__input {
    padding: 1rem 1.3rem;
    font-size: 14pt;
    border-radius: 2rem;
    border: 1pt solid black;
}

.contact__submit-btn {
    align-self: center;
    font-size: 16pt;
    width: fit-content;
    margin-top: 0.5rem !important;
    font-weight: normal !important;
}

.contact-us__confirmation {
    margin-top: 1rem;
    font-size: larger;
    text-align: center;
    font-weight: bold;
    color: green;
    transition: 400ms ease;
}

/* Media Queries (Medium Devices) */
@media (max-width: 1024px) {
    .contact-us__section {
        min-height: calc(100vh - 15rem);
    }

    .contact-us__heading {
        margin-bottom: 0;
    }

}

/* Media Queries (Small Devices) */
@media (max-width: 600px) {
    .contact-us__heading {
        font-size: 26pt;
        margin-bottom: 0.2rem !important;
    }


    .contact-us__information > a, .contact-us__information > p, .contact-us__information > span {
        font-size: 12pt;
    }

    .contact-us__information > span {
        margin: auto 5%;
    }

    .contact-us__form {
        margin: 3% 7%;
    }

    .contact-us__form__name, .contact-us__form__email, .contact-us__form__message {
        font-size: 13pt;
    }

    .contact-us__form__input {
        padding: 0.7rem;
        font-size: 12pt;
    }
}